import get from 'lodash/get'
import { graphql } from 'gatsby'
import React from 'react'

import { SiteProduct, HomeBrands, HomeNewsLetter, PageServices } from '../components'

import { Container, Col, Row } from 'reactstrap'

import SEO from '../components/seo'

class productTemplate extends React.Component {
  render() {
    const product = get(this, 'props.data.product')
    const title = get(product, 'frontmatter.title')
    const path = get(product, 'frontmatter.path')
    const schemaOrg = get(product, 'frontmatter.schemaOrg')
    const description = get(product, 'frontmatter.description')
    const coverImage = get(product, 'frontmatter.coverImage.childImageSharp.resolutions.src')

    const seoData = {
      title,
      description,
      coverImage,
      path,
      schemaOrg: schemaOrg.replace('%cover_image%', coverImage)
    }

    return (
      <>
        <SEO seoData={seoData} />
        <SiteProduct {...this.props} />
        <Container>
          <Row>
          <Col className="text-center"><h2>Nos services</h2>
          <PageServices />
          </Col>
          </Row>
        </Container>
        <HomeBrands />
        <HomeNewsLetter/>
      </>
    )
  }
}

export default productTemplate

export const productQuery = graphql`
  query ProductByPath($path: String!, $documentationPath: String) {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    product: markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        layout
        title
        path
        description
        schemaOrg
        vendor
        coverImage {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    documentation: markdownRemark(frontmatter: { path: { eq: $documentationPath } }) {
      frontmatter {
        path
      }
    }
    allImageSharp(filter: {fluid: {originalName: {in: [
      "toro.png",
      "hunter.png"
      "rainbird.png",
      "irritrol.png",
      "hydrorain.png", 
      "irriglobe.png",
      "rachio.png",
      "ez-flo.png",
      "fertilisation.png",
      "k-rain.png",
      "Rewatec.png"
    ]}}}) {
      edges {
        node {
          fluid(maxWidth: 400) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
